import React from "react";
import "./index.css";
import App from "./App";
import "./styles/home.css";
import "./styles/scss/index.scss";
import "./styles/line-awesome-1.3.0/css/line-awesome.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";

// const root = document.getElementById('root');
// ReactDOM.render(<App />, rootNode);
// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
